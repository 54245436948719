/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:b843a22a-ea53-40b8-a305-8fd8d85c3564",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_6SaiVdvp4",
    "aws_user_pools_web_client_id": "1hu2los67tvdqte14g732ivbnb",
    "oauth": {
        "domain": "quizappbackend70670ccd-70670ccd-staging.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.cloudbrevity.com/",
        "redirectSignOut": "https://www.cloudbrevity.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://ce5ejmmdyzgwtixgetnadq2m3e.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-z6jvu7rqebe7vmggprg6loyaiq"
};


export default awsmobile;
